import * as Sentry from '@sentry/react';
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import type { ReactNode } from 'react';
import { GlobalToastRegion } from '@pledge-earth/product-language';
import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { FallbackPage } from '../pages/public/shared/ErrorPage/ErrorPage';
import { ReduxSagaProvider } from '../store/provider';
import { reducer as settings } from '../store/settings/reducers';
import { Localization } from '../localization';
import { GoogleMapsAPIProvider } from '../components/Map/GoogleMapsAPIProvider';
import '../pages/public/shared/ErrorPage/ErrorPage.scss';
import { getEmbedKey, getIframeOrigin, getPublicUserId } from '../components/EmissionCalculator/utils';
import {
  PUBLIC_EMBED_FRAME_ORIGIN_HEADER,
  PUBLIC_EMBED_KEY_HEADER,
  PUBLIC_USER_ID_HEADER,
} from '../components/EmissionCalculator/constants/constants';
import result from '../services/graphql-public/generated';

import { FlagProvider } from './FlagProvider';

const reducer = combineReducers({
  settings,
});

const store = configureStore({
  reducer,
});

const headerLink = setContext((_request, previousContext) => ({
  headers: {
    ...previousContext.headers,
    [PUBLIC_USER_ID_HEADER]: getPublicUserId(),
    [PUBLIC_EMBED_KEY_HEADER]: getEmbedKey(),
    [PUBLIC_EMBED_FRAME_ORIGIN_HEADER]: getIframeOrigin(window, document),
  },
}));

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_PUBLIC_ENDPOINT,
});

const client = new ApolloClient({
  link: ApolloLink.from([headerLink, httpLink]),
  cache: new InMemoryCache({
    possibleTypes: result.possibleTypes,
    typePolicies: {
      Query: {
        fields: {
          public_untracked_emission: {
            read(_, { args, toReference }) {
              if (!args) {
                return null;
              }

              return toReference({
                __typename: 'Emission',
                public_id: args.public_id,
              });
            },
          },
        },
      },
      // emissions have two keys, id and public_id. Apollo can only use one or both, not either. We almost always query by public_id so we choose that as the key here.
      // see: https://github.com/apollographql/apollo-feature-requests/issues/75
      Emission: { keyFields: ['public_id'] },
    },
  }),
});

export function ProviderCalculator({ children }: { children: ReactNode }) {
  return (
    <ApolloProvider client={client}>
      <ReduxSagaProvider store={store}>
        <Localization>
          <Sentry.ErrorBoundary fallback={<FallbackPage />}>
            <FlagProvider isAnonymous={true}>
              <GoogleMapsAPIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_JAVASCRIPT_API_KEY || ''}>
                {children}
                <GlobalToastRegion />
              </GoogleMapsAPIProvider>
            </FlagProvider>
          </Sentry.ErrorBoundary>
        </Localization>
      </ReduxSagaProvider>
    </ApolloProvider>
  );
}
